
import { Action } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class PageErrorsGeneral extends Vue {
    @Action("auth/me") getAuth!: (dontUseStorage: boolean) => Promise<Account>;

    mounted() {
        // @ts-ignore
        if (this.$route.params.code === "401") {
            this.getAuth(true).catch((res: ErrorResponse) => {
                if (res.status === 401) {
                    this.$router.push({ name: "logout" });
                }
            });
        }
    }
}
